import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from "@/composables/usePermissions";
import { ModulesEnum } from "@/enums/ModulesEnum";

const EmployeesRoutes = [
    {
        path: '/employees',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'EmployeesModule.name',
            icon: 'mdi-account-group',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: ':id(\\d+|new)?',
                name: RouteNamesEnum.Employees,
                component: () => import('../views/employees-module/EmployeesView.vue'),
                meta: {
                    title: 'EmployeesModule.employees',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Employees),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'eq', v: null, visible: false },
                        { f: 'moved_to_reserve_at', o: 'eq', v: null, visible: false },
                    ],
                    moduleType: 'employees'
                }
            },
            {
                path: 'reserves/:id(\\d+|new)?',
                name: RouteNamesEnum.Reserves,
                component: () =>
                    import('../views/employees-module/EmployeesView.vue'),
                meta: {
                    title: 'EmployeesModule.reserves',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Employees),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'eq', v: null, visible: false },
                        { f: 'moved_to_reserve_at', o: 'neq', v: null, visible: false },
                    ],
                    moduleType: 'employees-reserves'
                },
            },
            {
                path: 'archive/:id(\\d+|new)?',
                name: RouteNamesEnum.EmployeesArchive,
                component: () =>
                    import('../views/employees-module/EmployeesView.vue'),
                meta: {
                    title: 'EmployeesModule.archive',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Employees),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'neq', v: null, visible: false },
                    ],
                    moduleType: 'employees-archive'
                },
            },
            {
                path: 'overview',
                name: RouteNamesEnum.EmployeesOverview,
                component: () =>
                    import('../views/employees-module/EmployeeOverviewView.vue'),
                meta: {
                    title: 'EmployeesModule.module-overview',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Employees),
                    showInMenu: true,
                },
            },
            {
                path: 'files',
                name: RouteNamesEnum.EmployeesModuleFiles,
                component: () =>
                    import('../views/employees-module/ModuleFilesView.vue'),
                meta: {
                    title: 'EmployeesModule.module-files',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Employees),
                    showInMenu: true,
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'job-positions/:id(\\d+|new)?',
                        name: RouteNamesEnum.JobPositions,
                        component: () =>
                            import('../views/employees-module/JobPositionsView.vue'),
                        meta: {
                            title: 'EmployeesModule.job-positions',
                            auth: {
                                required: false,
                                roles: [],
                            },
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'companies/:id(\\d+|new)?',
                        name: RouteNamesEnum.Companies,
                        component: () =>
                            import('../views/employees-module/CompaniesView.vue'),
                        meta: {
                            title: 'EmployeesModule.companies',
                            auth: {
                                required: false,
                                roles: [],
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Companies),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'licences/:id(\\d+|new)?',
                        name: RouteNamesEnum.Licences,
                        component: () =>
                            import('../views/employees-module/LicencesView.vue'),
                        meta: {
                            title: 'EmployeesModule.licences',
                            auth: {
                                required: false,
                                roles: [],
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Licences),
                            showInMenu: true,
                        },
                    },
                ],
            },
        ],
    }
];

export default EmployeesRoutes;
