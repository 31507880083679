<template>
    <v-snackbar
        v-model="globalComponentsStore.showSnackbar"
        :timeout="globalComponentsStore.timeout"
        :class="globalComponentsStore.class"
        position="fixed"
        location="top"
        min-width="0"
    >
        <pre>{{ globalComponentsStore.text }}</pre>
        <template v-slot:actions>
            <v-btn
                variant="text"
                @click="globalComponentsStore.hideSnackbar"
                class="mr-2"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
    <Loading v-if="globalComponentsStore.showLoading"/>
    <MainMenu v-if="storeLogin.getIsAuthenticated" :key="currentRoute.fullPath.split('?')[0]"/>
    <RouterView
        :key="currentRoute.fullPath.split('?')[0]"
    />
    <ConfirmDialog
        @confirm="confirmDialog.onConfirm"
    />
</template>

<script lang="ts">
import { useRoute } from 'vue-router';
import { defineComponent, watch } from 'vue';
import { useGlobalComponentsStore } from '@/stores/global';
import { useLoginStore } from '@/stores/loginStore';
import MainMenu from '@/components/general/MainMenu.vue';
import Loading from '@/components/general/Loading.vue';
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import { useConfirmDialog } from "@/composables/useConfirmDialog";
import { isBiggerFontSize, isContrastColor, setBiggerFontSize, setContrastColor } from "@/helpers/accessibility";

export default defineComponent({
    name: 'App',
    components: {
        ConfirmDialog,
        MainMenu,
        Loading,
    },
    data() {
        return {};
    },
    methods: {},
    setup() {
        const globalComponentsStore = useGlobalComponentsStore();
        watch(
            () => globalComponentsStore.text,
            () => {
                globalComponentsStore.toggleShowSnackbar();
            }
        );

        if (isBiggerFontSize()) {
            setBiggerFontSize(true)
        }

        if (isContrastColor()) {
            setContrastColor(true);
        }
        return {
            currentRoute: useRoute(),
            storeLogin: useLoginStore(),
            globalComponentsStore,
            confirmDialog: useConfirmDialog(),
        };
    },
});
</script>
<style lang="scss">
@import '@/assets/css/main.scss';

html {
    overflow-y: auto !important;
}

.notification-success {
    & .v-overlay__content {
        background-color: var(--color-succes);
    }
}

.notification-warning {
    & .v-overlay__content {
        background-color: var(--color-warning);
    }
}

.notification-error {
    & .v-overlay__content {
        background-color: var(--color-danger);
    }
}

.bottom {
    display: flex;
    flex-flow: column nowrap;
}

.bottom .scrollbar-table {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

//TODO: TOTO ODSTRANIT PO AKTUALIZACI VUETIFY
.v-autocomplete .v-field__append-inner i {
    cursor: pointer;
}

.main-content {
    max-width: 100%;
}

.top-wrapper {
    @include display-flex(row, nowrap, flex-start, stretch);
    min-height: 100%;
    & .left,
    & .right {
        width: 50%;
        //border: 1px solid red
    }
    & .full {
        width: 100%;
    }
    & .right {
        padding: 16px;
        background-color: var(--blue-light);
        @include display-flex(column, nowrap, initial, initial);
    }
}

.font-size-html,
.v-field {
    font-size: 1rem !important;
}

.new {
    background-color: transparent !important;
    box-shadow: none !important;

    & .icon {
        margin-right: 8px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.bg-blue-light {
    background-color: var(--blue-light);
}

html.v-overlay-scroll-blocked {
    position: static !important;

    body:not([data-pdfjsprinting="true"]) {
        position: fixed;
        top: var(--v-body-scroll-y);
        left: var(--v-body-scroll-x);
        width: 100%;
        height: 100%;
    }
}

.v-list-item--density-default.v-list-item--one-line {
    min-height: 33px !important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline: 10px !important;
}
</style>

<style scoped lang="scss">
    pre {
        font-family: var(--font-family-text);
    }
</style>
